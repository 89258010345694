<template>
  <div class="profile-tab">
    <section class="section" v-if="currentUser">
      <h1 class="title is-marginless">
        Profile settings
      </h1>
      <div class="section mt-2">
        <b-notification
          :active="showError"
          @close="closeError"
          type="is-danger"
          >{{ showError ? error.message : "" }}</b-notification
        >
        <div class="profile-info-bloc box" style="position: relative;">
          <h2 class="title is-5 mb-3">
            Credentials
          </h2>
          <h2 v-if="modifyEmail" class="subtitle">
            To modify your email please type your current password
          </h2>
          <h2 v-if="modifyPassword" class="subtitle">
            To choose a new password, please type your current password.
            <br />
            <p class="is-size-6 is-italic">
              If you don't remember your current password, log-out and click on
              the link "I forgot my password" on the login page.
            </p>
          </h2>
          <b-field
            horizontal
            v-if="modifyEmail || modifyPassword"
            :type="{ 'is-danger': $v.newAuthData.oldPassword.$error }"
            label="Current Password* :"
          >
            <b-input
              v-model="$v.newAuthData.oldPassword.$model"
              type="password"
              minlength="6"
              password-reveal
            ></b-input>
          </b-field>
          <b-field
            horizontal
            v-if="!modifyPassword"
            :label="modifyEmail ? 'New Email :' : 'Email :'"
          >
            <em
              v-if="!modifyEmail && authUser"
              class="is-size-5 has-text-grey-light has-text-weight-normal"
              >{{ authUser.email }}</em
            >
            <b-input
              v-if="modifyEmail"
              v-model="$v.newAuthData.email.$model"
              type="email"
              @keyup.enter.native="updateAuthData"
            ></b-input>
          </b-field>
          <b-field
            horizontal
            v-if="!modifyEmail"
            :label="modifyPassword ? 'New Password* :' : 'Password :'"
          >
            <em
              v-if="!modifyPassword && authUser"
              class="is-size-5 has-text-grey-light has-text-weight-normal"
              >{{
                authUser.providerData[0].providerId === "password"
                  ? "xxxxxx"
                  : "Google account"
              }}</em
            >
            <b-input
              v-if="modifyPassword"
              v-model="$v.newAuthData.newPassword.$model"
              type="password"
              @keyup.enter.native="updateAuthData"
              password-reveal
            ></b-input>
          </b-field>
          <div
            class="has-text-right"
            v-if="
              authUser && authUser.providerData[0].providerId === 'password'
            "
          >
            <b-button
              class="mr10"
              type="is-warning"
              @click="modifyEmail = true"
              v-if="!modifyEmail && !modifyPassword"
              >Edit Email</b-button
            >
            <b-button
              type="is-warning"
              @click="modifyPassword = true"
              v-if="!modifyEmail && !modifyPassword"
              >Edit Password</b-button
            >
            <b-button
              type="is-danger"
              class="mr10"
              @click="resetModif('auth')"
              v-if="modifyEmail || modifyPassword"
              >Cancel</b-button
            >
            <b-button
              type="is-success"
              @click="updateAuthData"
              v-if="modifyEmail || modifyPassword"
              :disabled="$v.newAuthData.$invalid"
              >OK</b-button
            >
          </div>
          <div class="box-divider"></div>
          <!-- PROFILE INFO (NAME, PROFILE TYPE, NOTIF..) -->
          <h2 class="title is-5 mb-3">
            Personal informations
          </h2>
          <b-field
            v-if="!currentUser.firstname"
            horizontal
            label="Name* :"
            :type="{ 'is-danger': $v.newProfileData.name.$error }"
          >
            <em
              v-if="!modifyPrivate"
              class="is-size-5 has-text-grey-light has-text-weight-normal"
              >{{ currentUser.name }}</em
            >
            <b-input
              v-if="modifyPrivate"
              v-model="$v.newProfileData.name.$model"
              type="text"
              required
              @keyup.enter.native="updatePrivateData"
            ></b-input>
          </b-field>
          <b-field
            v-if="currentUser.firstname"
            horizontal
            label="First Name* :"
          >
            <em
              v-if="!modifyPrivate"
              class="is-size-5 has-text-grey-light has-text-weight-normal"
              >{{ currentUser.firstname }}</em
            >
            <b-input
              v-if="modifyPrivate"
              v-model="newProfileData.firstname"
              type="text"
              required
              @keyup.enter.native="updatePrivateData"
            ></b-input>
          </b-field>
          <b-field v-if="currentUser.lastname" horizontal label="Last Name* :">
            <em
              v-if="!modifyPrivate"
              class="is-size-5 has-text-grey-light has-text-weight-normal"
              >{{ currentUser.lastname }}</em
            >
            <b-input
              v-if="modifyPrivate"
              v-model="newProfileData.lastname"
              type="text"
              required
              @keyup.enter.native="updatePrivateData"
            ></b-input>
          </b-field>
          <b-field horizontal label="Profile type :">
            <em
              v-if="!modifyPrivate"
              class="is-size-5 has-text-grey-light has-text-weight-normal"
            >
              <b-icon
                size="is-small"
                :icon="profileIcons[currentUser.profileType]"
              ></b-icon>
              {{ currentUser.profileType | capitalize }}
            </em>
            <b-select
              v-if="modifyPrivate"
              placeholder="Select a type"
              :icon="profileIcons[newProfileData.profileType]"
              v-model="newProfileData.profileType"
            >
              <option value="business">Business</option>
              <option value="education">Education</option>
            </b-select>
          </b-field>
          <b-field horizontal class="checkbox-field">
            <template slot="label">
              Notifications
              <b-tooltip
                style="cursor: help;"
                multilined
                type="is-dark"
                :label="
                  `I promise I won't spam you and your data will never be shared with anyone else.`
                "
              >
                <b-icon size="is-small" icon="help-circle-outline"></b-icon>
              </b-tooltip>
              :
            </template>
            <b-checkbox
              v-model="newProfileData.optinUpdates"
              :disabled="!modifyPrivate"
            >
              Allow email updates about new features
            </b-checkbox>
          </b-field>
          <div class="has-text-right">
            <b-button
              type="is-warning"
              @click="modifyPrivate = true"
              v-if="!modifyPrivate"
              >Edit Profile</b-button
            >
            <b-button
              type="is-danger"
              class="mr10"
              @click="resetModif('profile')"
              v-if="modifyPrivate"
              >Cancel</b-button
            >
            <b-button
              type="is-success"
              @click="updatePrivateData"
              v-if="modifyPrivate"
              :disabled="$v.newProfileData.$invalid"
              >OK</b-button
            >
          </div>
          <div class="box-divider"></div>
          <!-- INSTRUCTOR PAGE -->
          <h2 class="title is-5 mb-3">
            Instructor page
          </h2>
          <b-field horizontal label="Page status :">
            <b-switch v-model="newPage.activePage" :disabled="!modifyPage">{{
              newPage.activePage ? "Active" : "Disabled"
            }}</b-switch>
          </b-field>
          <b-field horizontal label="Page name :">
            <em
              v-if="!modifyPage"
              class="is-size-5 has-text-grey-light has-text-weight-normal"
              >{{ publicProfile ? publicProfile.pageName : "" }}</em
            >
            <b-input
              v-if="modifyPage"
              v-model.trim="newPage.pageName"
              type="text"
              @keyup.enter.native="updatePublicProfile"
            ></b-input>
          </b-field>
          <b-field horizontal label="Page link :">
            <em
              v-if="
                (publicProfile && !publicProfile.activePage) ||
                  !newPage.activePage
              "
              class="is-size-5 has-text-grey-light has-text-weight-normal"
              >Your public page is hidden. Activate it to generate its link.</em
            >
            <router-link
              v-if="
                newPage.activePage && publicProfile && publicProfile.activePage
              "
              target="_blank"
              rel="noreferrer"
              :to="`/user/${authUser.uid}`"
            >
              <b id="page-link" class="is-size-5 has-text-success">{{
                zensimuSite && publicProfile.zsShortLink
                  ? publicProfile.zsShortLink
                  : publicProfile.shortLink
              }}</b>
            </router-link>
          </b-field>
          <div class="has-text-right">
            <b-button
              type="is-warning"
              @click="modifyPage = true"
              v-if="!modifyPage"
              >Edit Instructor Page</b-button
            >
            <b-button
              type="is-danger"
              class="mr10"
              @click="resetModif('page')"
              v-if="modifyPage"
              >Cancel</b-button
            >
            <b-button
              type="is-success"
              @click="updatePublicProfile"
              v-if="modifyPage"
              >OK</b-button
            >
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { required, email, minLength } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
// import allCountries from "@/assets/premium/countries.json";

export default {
  name: "accountTab",
  computed: {
    ...mapGetters([
      "currentUser",
      "authUser",
      "error",
      "showError",
      "publicProfile",
      "zensimuSite"
    ])
  },
  mounted() {
    this.resetModif();
  },
  methods: {
    closeError() {
      this.$store.commit("clearError");
    },
    updatePublicProfile() {
      if (
        !this.publicProfile ||
        this.newPage.activePage !== this.publicProfile.activePage ||
        this.newPage.pageName !== this.publicProfile.pageName
      ) {
        this.$store
          .dispatch("updatePublicProfile", {
            uid: this.authUser.uid,
            obj: this.newPage
          })
          .then(() => {
            this.$buefy.toast.open("Instructor page updated");
            this.resetModif("page");
          });
      } else {
        this.resetModif("page");
      }
    },
    updateAuthData() {
      this.$v.$touch();
      if (!this.$v.newAuthData.$invalid) {
        this.$store
          .dispatch("updateUserAuth", this.newAuthData)
          .then(() => {
            this.$buefy.toast.open("Credentials updated");
            this.resetModif("auth");
          })
          .catch(() => {
            this.$buefy.toast.open("Couldn't update credentials");
            this.resetModif("auth");
          });
      }
    },
    updatePrivateData() {
      this.$v.$touch();
      let updateData = {
        uid: this.currentUser.uid
      };
      Object.keys(this.newProfileData).forEach(key => {
        if (
          this.newProfileData[key] !== undefined &&
          this.newProfileData[key] !== null &&
          this.newProfileData[key] !== this.currentUser[key]
        ) {
          updateData[key] = this.newProfileData[key];
        }
      });
      if (
        Object.keys(updateData).length > 1 &&
        !this.$v.newProfileData.$invalid
      ) {
        this.$store.dispatch("updateUserProfile", updateData).then(() => {
          this.$buefy.toast.open("Profile updated");
          this.resetModif("profile");
        });
      } else {
        this.resetModif("profile");
      }
    },
    resetModif(type) {
      if (!type || type === "auth") {
        this.modifyPassword = false;
        this.modifyEmail = false;
        if (this.authUser) {
          this.newAuthData["email"] = this.authUser["email"];
        }
      }
      if (!type || type === "profile") {
        Object.keys(this.currentUser).forEach(key => {
          this.newProfileData[key] = this.currentUser[key];
        });
        this.modifyPrivate = false;
        this.modifyAddress = false;
      }
      if (!type || type === "page") {
        this.modifyPage = false;
        if (this.publicProfile && this.publicProfile.activePage) {
          this.newPage.activePage = this.publicProfile.activePage;
          this.newPage.pageName = this.publicProfile.pageName || "All Games";
        }
      }
    }
  },
  validations: {
    newAuthData: {
      email: {
        required,
        email
      },
      newPassword: {
        minLength: minLength(6)
      },
      oldPassword: {
        required,
        minLength: minLength(6)
      }
    },
    newProfileData: {
      name: {
        required
      }
    }
  },
  data() {
    return {
      modifyPage: false,
      modifyPrivate: false,
      modifyPassword: false,
      modifyEmail: false,
      modifyAddress: false,
      profileIcons: {
        education: "school",
        business: "briefcase"
      },
      newAuthData: {
        email: "",
        oldPassword: "",
        newPassword: ""
      },
      newProfileData: {
        name: "",
        firstname: "",
        lastname: "",
        profileType: "",
        optinUpdates: true,
        billingAddress: {
          name: ""
        }
      },
      newPage: {
        activePage: false,
        pageName: "All Games"
      }
    };
  }
};
</script>
<style lang="scss">
.profile-tab {
  .profile-info-bloc {
    // margin-top: 1em;
    // padding: 1em 1.5em;
    .field-label.is-medium {
      padding: 0;
    }
    .mr10 {
      margin-right: 1em;
    }
    .field-body {
      flex-grow: 3 !important;
    }
    .checkbox-field {
      .field-body {
        padding-top: 0.4em;
      }
    }
  }
}
</style>
